import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// Services
import ServicesView from '../views/services/IndexView.vue'
import ServiceDetailView from '../views/services/DetailView.vue'

import AboutUsView from '../views/AboutUsView.vue'
import ContactView from '../views/ContactView.vue'
import NotFoundView from '../views/NotFoundView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // Services
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/services/:service',
    name: 'srv-detail',
    component: ServiceDetailView
  },
  //
  {
    path: '/us',
    name: 'us',
    component: AboutUsView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  { 
    path: '/:catchAll(.*)', 
    component: NotFoundView,
    name: '404'
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
})

export default router
